import React from 'react'
import { Box, Link, SimpleGrid, Center, Heading, Text } from "@chakra-ui/react"

const links = [{
    href: 'https://podcasts.apple.com/tw/podcast/%E9%A0%82%E6%A8%93%E5%8A%A0%E8%93%8B-overbuild/id1477052010',
    alt: 'Link to access apple podcast',
    text: 'Apple Podcast',
}, {
    href: 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy9kOGQ5MjhjL3BvZGNhc3QvcnNz',
    alt: 'Link to access google podcast',
    text: 'Google Podcast',
}, {
    href: 'https://open.spotify.com/show/2sQKI1FAWhp8ZxptCReUbi',
    alt: 'Link to access spotify podcast',
    text: 'Spotify',
}, {
    href: 'https://overcast.fm/itunes1477052010/overbuild',
    alt: 'Link to access overcast podcast',
    text: 'Overcast',
}, {
    href: 'https://pca.st/FJefgN',
    alt: 'Link to access pocket cast podcast',
    text: 'Pocket Cast',
}, {

    href: 'https://player.soundon.fm/p/ba502ddb-ad77-4ddc-aedf-f5d052f04863/',
    alt: 'Link to access soundon',
    text: 'SoundOn',
}, {
    href: 'https://www.youtube.com/channel/UCzFfSrF6-v3Ey5i95w48noQ/',
    alt: 'Link to YouTube channel',
    text: 'YouTube',
}]

export default function AvailablePlatforms() {
    return (
        <Box marginTop={5}>
            <Heading as="h3" size="md" marginBottom={2}>在其他平台收聽</Heading>
            <Text>
                你也可以使用任何一個 podcast 播放軟體直接訂閱我們的 <Link color='teal' href="https://open.firstory.me/rss/user/ck1npa6vwf2930786sj5qsoec" target="_blank"> rss</Link>
            </Text>
            <SimpleGrid columns={2} spacing={2}>
                {links.map(link => (
                    <Center key={`link-${link.href}`}>
                        <Link color='teal' isExternal href={link.href} alt={link.alt}>
                            {link.text}
                        </Link>
                    </Center>
                ))}
            </SimpleGrid>
        </Box>
    )
}
