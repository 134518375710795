import React from "react"
import AudioPlayer from 'react-h5-audio-player'
import { graphql } from "gatsby"
import { Heading, Box } from "@chakra-ui/react"
import Layout from '../components/Layout'
import AvailablePlatforms from '../components/AvailablePlatforms'
import 'react-h5-audio-player/lib/styles.css'

const preprocessContent = (content) => {
    let res = content
    // add urls
    res = res.replace(/\((https?:\/\/)([^)]+)\)/ig, (match, p1, p2) => {
        const url = `${p1}${p2}`
        return `<a href="${url}" target="_blank">(${url})</a>`
    })
    // add twitter
    res = res.replace(/twitter: @overbuild_io/i, `<a href="https://twitter.com/overbuild_io">Twitter: @overbuild_io</a>`)
    // add ig
    res = res.replace(/i(:?nsta)?g(:?ram)?: @overbuild_io/i, `<a href="https://www.instagram.com/overbuild_io">instagram: @overbuild_io</a>`)
    // add mailto
    res = res.replace(/contact\.overbuild@gmail\.com/i, `<a href="mailto:contact.overbuild@gmail.com" target="_blank">contact.overbuild@gmail.com</a>`)
    return res
}

export default ({ data }) => {
    const item = data.allSitePage.edges[0].node.context
    const createMarkup = () => ({ __html: preprocessContent(item.content) })
    return (
        <Layout>
            <Heading as="h1" size="lg">
                {item.title}
            </Heading>
            <Box paddingTop={5} paddingBottom={5}>
            <AudioPlayer
                src={item.enclosure.url}
            />
            </Box>
            <Box p={1} dangerouslySetInnerHTML={createMarkup()} sx={{
                'a': {
                    color: 'teal'
                }
            }} />
            <AvailablePlatforms />
        </Layout>
    )
}

export const queyr = graphql`
query ($path: String!) {
    allSitePage(filter: { path: { eq: $path }}) {
        edges {
            node {
                context {
                    id
                    title
                    content
                    enclosure {
                        url
                    }
                    itunes {
                        summary
                    }
                }
            }
        }
    }
}
`
